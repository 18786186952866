<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Resumo das Formações</h1>
    <div class="p-col-12 p-md-3 p-mt-1"></div>
    <div style="height: 65vh">
      <DataTable
        v-if="loggedUser.isEsa || loggedUser.department == 'Administradores'"
        ref="dt"
        :id="year"
        :value="activeUsers"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        sortField="trainingAction.name"
        :sortOrder="1"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <label for="year-selector" class="p-mr-1 p-my-auto">Ano</label>
            <InputNumber
              name="year"
              :value="year"
              class="p-ml-2 p-mr-2"
              showButtons
              buttonLayout="stacked"
              inputStyle="width:65px"
              :step="1"
              :min="currentYear - 10"
              :max="currentYear"
              :useGrouping="false"
              @input="yearChanged"
            />
          </div>
        </template>
        <Column
          field="username"
          header="Colaborador"
          sortable
          filterField="username"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="activeUsers"
              :optionLabel="'username'"
              :optionValue="'username'"
              class="p-column-filter"
              :showClear="true"
              :filter="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="employeeStatus"
          header="Estado"
          sortable
          filterField="employeeStatus"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.employeeStatus == 1 ? "Activo" : "Inactivo" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 1, label: 'Activo' },
                { value: 3, label: 'Inactivo' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="department"
          header="Departamento"
          sortable
          filterField="department"
        >
          <template #body="slotProps">
            {{ slotProps.data.department }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="office"
          header="Escritório"
          sortable
          filterField="office"
        >
          <template #body="slotProps">
            {{ slotProps.data.office }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column header="Formação Prevista">
          <template #body="slotProps">
            {{ userExpectedTraining(slotProps.data.id) }} Horas
          </template>
        </Column>
        <Column header="Formação Realizada">
          <template #body="slotProps">
            {{ calculateTotalTraining(slotProps.data.id) }} Horas
          </template>
        </Column>
        <Column header="Percentagem Concluída">
          <template #body="slotProps">
            {{
              getPercentage(
                calculateTotalDuration(slotProps.data.id),
                userExpectedTraining(slotProps.data.id)
              )
            }}
            %
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-plus"
              class="p-button-rounded p-button-success p-button-outlined p-mr-2"
              @click="addParticipation(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '80vw', position: 'relative' }"
      :header="
        'Criar Participação em Formação para ' + userParticipation.username
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
      @hide="closeForm"
    >
      <form
        name="participationForm"
        class="p-col-12"
        @submit.prevent="saveParticipation"
        style="min-height: 50vh"
      >
        <div>
          <b>Funcionário: </b> {{ userParticipation.username }} <br />
          <b>Horas de Formação Prevista: </b> {{ plannedTraining }} Horas<br />
          <b>Horas de Formação Realizada: </b>
          {{ numToTime(usersTotals[userParticipation.id]) }} <br />
          <b>Horas de Formação em Falta: </b>
          {{ numToTime(plannedTraining - usersTotals[userParticipation.id]) }}
          <br />
        </div>
        <div class="p-fluid p-formgrid p-grid p-mt-4">
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                v-model="fake.training_action_id"
                name="training"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('training') },
                  'form-control',
                ]"
                :options="userParticipation.trainings"
                :optionLabel="getTrainingLabel"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
                :required="true"
                @change="trainingChange"
              >
              </Dropdown>
              <label for="inputtext">Formação</label>
            </span>
            <small v-if="errors.has('training')" class="p-error" role="alert"
              >Formação é obrigatório</small
            >
          </div>

          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'start'"
                  :id="'start'"
                  v-model="fake.start"
                  v-validate="'required'"
                  style="width: 100%"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          {
                            'p-error': errors.has('start'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Dia de Inicio</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small v-if="errors.has('start')" class="p-error" role="alert"
              >Dia de Inicio é obrigatório</small
            >
          </div>

          <div class="p-field p-col-6 p-md-2 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                name="hours"
                suffix=" horas"
                v-model="fake.hours"
                v-validate="'required'"
                @input="hoursChange"
                v-bind:class="[
                  { 'p-invalid': errors.has('hours') },
                  'form-control',
                ]"
              />
              <label for="hours">N. Horas de Formação</label>
            </span>
            <small v-if="errors.has('hours')" class="p-error" role="alert">
              Horas de Formação é obrigatório
            </small>
          </div>

          <div class="p-field p-col-6 p-md-2 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                name="days"
                suffix=" dias"
                v-model="fake.days"
                @input="daysChange"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('days') },
                  'form-control',
                ]"
              />
              <label for="days">N. Dias de Formação</label>
            </span>
            <small v-if="errors.has('days')" class="p-error" role="alert">
              Dias de Formação é obrigatório
            </small>
          </div>

          <div class="p-field p-col-6 p-md-2 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                name="maxDuration"
                suffix=" horas"
                :disabled="true"
                v-model="fake.durationDay"
                v-bind:class="['form-control']"
              />
              <label for="maxDuration">Horas de Formação/Dia</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveParticipation"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import trainingService from "../services/training.service";
import employeeService from "../services/employee.service";
import { getOnlyDate } from "../helpers/helpers";
export default {
  name: "TrainingResume",
  data() {
    return {
      plannedTraining: 30,
      loading: true,
      participationList: [],
      activeUsers: [],
      usersTotals: [],
      expectedTraining: [],
      filters: {
        username: { value: null, matchMode: FilterMatchMode.EQUALS },
        employeeStatus: { value: 1, matchMode: FilterMatchMode.EQUALS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        office: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      showForm: false,
      year: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      userParticipation: {},
      fake: { durationDay: 0 },
    };
  },
  async created() {
    this.loggedUser = this.$store.state.auth.user;
    await this.getExpectedTraining();
    await this.getActiveUsers();
    await this.getUserParticipation();
    this.loading = false;
  },
  methods: {
    async getExpectedTraining() {
      this.expectedTraining = [];
      return trainingService.getExpectedTraining(this.year).then((response) => {
        return (this.expectedTraining = response);
      });
    },
    async getActiveUsers() {
      return employeeService.getList().then((response) => {
        this.activeUsers = response.filter(
          (user) =>
            user.id != 130 &&
            user.id != 132 &&
            user.id != 133 &&
            user.id != 131 &&
            user.id != 1
        );
      });
    },
    async getUserParticipation() {
      this.participationList = [];
      return trainingService
        .userParticipationsByYear(this.year)
        .then((response) => {
          return (this.participationList = response);
        });
    },
    numToTime(number) {
      var hour = Math.floor(number);
      var decpart = number - hour;
      var min = 1 / 60;
      decpart = min * Math.round(decpart / min);
      var minute = Math.floor(decpart * 60) + "";
      if (minute.length < 2) {
        minute = "0" + minute;
      }
      let time = hour + ":" + minute;

      return time;
    },
    getPercentage(partial, total) {
      if (total == 0) {
        return 0;
      }
      let percentage = Math.round((partial / total) * 100).toFixed(2);
      return new Intl.NumberFormat("pt-PT").format(percentage);
    },
    calculateTotalTraining(userId) {
      let participations = this.participationList.filter(
        (part) => part.user_id == userId
      );
      const total = participations.reduce(
        (prev, next) => prev + next.duration,
        0
      );
      this.usersTotals[userId] = total;
      return this.numToTime(total);
    },
    calculateTotalDuration(userId) {
      let participations = this.participationList.filter(
        (part) => part.user_id == userId
      );
      const total = participations.reduce(
        (prev, next) => prev + next.duration,
        0
      );
      return total;
    },
    async yearChanged(year) {
      if (year == this.year) {
        return;
      }
      this.loading = true;
      this.year = year;
      await this.getExpectedTraining();
      await this.getUserParticipation();
      return (this.loading = false);
    },
    getUserTrainings(userId) {
      return trainingService.userTrainings(userId).then((response) => response);
    },
    async addParticipation(user) {
      if (user.trainings == undefined) {
        /* eslint-disable require-atomic-updates */
        user.trainings = await this.getUserTrainings(user.id);
      }
      this.userParticipation = user;
      this.fake.userId = user.id;
      this.showForm = true;
    },
    saveParticipation() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        return this.saveFakeParticipation();
      });
    },
    closeForm() {
      this.showForm = false;
      this.fake = {};
      this.$validator.pause();
      this.$validator.reset();
    },
    getTrainingLabel(info) {
      return `${info.name} | ${info.duration} horas`;
    },
    calculateDurationDay() {
      return (this.fake.durationDay = this.fake.hours / this.fake.days);
    },
    trainingChange(info) {
      let selected = this.userParticipation.trainings.filter(
        (training) => training.id == info.value
      );
      selected = selected[0];
      if (this.fake.hours == undefined || this.fake.hours > selected.duration) {
        this.fake.hours = selected.duration;
      }
      if (this.fake.days == undefined) {
        this.fake.days = 1;
      }
      this.fake.maxDuration = selected.duration;
      return this.calculateDurationDay();
    },
    hoursChange(value) {
      if (this.fake.days == undefined) {
        this.fake.days = 1;
      }
      if (this.fake.maxDuration == undefined) {
        this.fake.maxDuration = 0;
      }
      this.fake.hours = value;
      return this.calculateDurationDay();
    },
    daysChange(value) {
      if (this.fake.hours == undefined) {
        this.fake.hours = 0;
      }
      if (this.fake.maxDuration == undefined) {
        this.fake.maxDuration = 0;
      }
      this.fake.days = value;
      return this.calculateDurationDay();
    },
    saveFakeParticipation() {
      let bodyParams = {
        training_action_id: this.fake.training_action_id,
        user_id: this.fake.userId,
        start: getOnlyDate(this.fake.start),
        days: this.fake.days,
        hours: this.fake.hours,
      };
      trainingService.createFakeParticipation(bodyParams).then((response) => {
        if (!response) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar a participação na formação",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Participação na Formação Criada",
            detail: "A participação na formação foi criada com sucesso",
            life: 3000,
          });
        }
      });
      this.getUserParticipation();
      return this.closeForm();
    },
    userExpectedTraining(userId) {
      return this.expectedTraining.reduce(function (sum, current) {
        if (current.user_id == userId) {
          return sum + current.expectedTraining;
        }
        return sum + 0;
      }, 0);
    },
  },
};
</script>
<style></style>
